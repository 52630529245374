<template>
    <v-footer class="text-center d-flex flex-column">
        <div>
            <img src="../assets/cloudpunchMedia.png" alt="Cloudpunch Media" />
        </div>
    </v-footer>
</template>

<script>
    export default {
        name: "PageFooter",
    };
</script>

<style scoped>
    .v-footer {
        background-color: #2c3440;
        margin-top: auto;
    }
    img {
        width: 200px;
    }
</style>
